import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Subscription plan component
 *
 * @param {Object} props
 */
const SubscriptionPlan = ({
                              monthPrice,
                              yearPrice,
                              url,
                              monthPriceFull,
                              yearPriceFull,
                              urlFull,
                              features,
                              turnOnFullLabel,
                              buttonLabel,
                              checkboxId
                          }) => {
    const [full, setFull] = useState(false);

    const currentMonthPrice = full ? monthPriceFull : monthPrice;
    const currentYearPrice = full ? yearPriceFull : yearPrice;
    const currentUrl = full ? urlFull : url;

    const handleChange = (event) => {
        setFull(event.target.checked);
    };

    return (
        <div className="subscription-plan">
            <div className="promo-card-features">
                {features.map((feature, index) => <span key={index} className="promo-card-features__item">{feature}</span>)}
                <span className="promo-card-features__item">
                    <span className="custom-checkbox-wrapper">
                        <input id={checkboxId} className="custom-checkbox" type="checkbox" onChange={handleChange}/>
                        <label htmlFor={checkboxId} className="custom-checkbox-label">{turnOnFullLabel}</label>
                    </span>
                </span>
            </div>
            <div className="promo-card-price">
                <span className="promo-card-price__month">{currentMonthPrice}</span>
                <span className="promo-card-price__year">{currentYearPrice}</span>
            </div>
            <div className="promo-card-action">
                <a href={currentUrl} className="promo-card-action__link custom-btn custom-btn--red">
                    <span className="custom-btn__title">{buttonLabel}</span>
                </a>
            </div>
        </div>
    );
};

SubscriptionPlan.displayName = 'SubscriptionPlan';

SubscriptionPlan.propTypes = {
    monthPrice: PropTypes.string.isRequired,
    yearPrice: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,

    monthPriceFull: PropTypes.string.isRequired,
    yearPriceFull: PropTypes.string.isRequired,
    urlFull: PropTypes.string.isRequired,

    features: PropTypes.arrayOf(PropTypes.string).isRequired,

    turnOnFullLabel: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    checkboxId: PropTypes.string.isRequired
};

export default SubscriptionPlan;
